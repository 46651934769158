import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../../styles/core.css"
import "../../styles/styles.css"
import Layout from "../../components/Layout"
import MenuOne from "../../components/MenuOne"
import MenuTwo from "../../components/MenuTwo"
import Gallery from "../../components/Gallery"
import Content from "../../components/Content"

const galImgs = ['/page-images/10.jpg']

export default function AboutUs() {
  return (
    <Layout breadcrumbs={["The Team"]}>
 
    <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )} 
    />

    <Content>

      <p><strong>The Team</strong></p>
      <p>	We regard design not as an elitist function but as a collaborative process involving input and feedback not only from clients but also from contractors, specialist sub-contractors and suppliers. </p>
      <p>	On the majority of projects, we seek the expertise of other consultants and call upon the services of quantity surveyors, structural engineers and planning consultants, amongst others, to provide a packaged service.  It is normally our role to arrange and coordinate these outside consultants such that our clients maintain a single point of contact.</p>
      <p> <strong>Principal:</strong></p>
      <p>	Ian Crockard BSc (Hons) Arch, MCIAT</p>
      <p> <strong>Architectural Technician:</strong></p>
      <p>	Andrew McKibbin BSc (Hons)</p>

    </Content>


      <div class="region region-content-bottom">
        <MenuOne active="about-us"></MenuOne>
        <MenuTwo
          active="the-team"
          items={['../profile', '../design-approach', '../value-and-commitment', '../the-team']}>
        </MenuTwo>
      </div>

  </Layout>

  )
}
